import { Link } from "@fluentui/react";
import React from "react";
import styled from "styled-components";

const Title = styled.p({
    fontSize: '1.2rem',
    lineHeight: '115%',
    color: '#FFC000'
});

const Container = styled.div({
    paddingTop: '2vh',
    paddingBottom: '2vh',
    maxWidth: '50vw',
});

const PdfLink = styled(Link)({
});

export default function Terms() {
    return (
        <Container>
            <PdfLink href="/assets/pdf/Mietbedingungen_Baumaschinen.pdf" target="_blank" rel="noopener noreferrer">Mietbedingungen Baumaschine</PdfLink><br/>
            <PdfLink href="/assets/pdf/Mietbedingungen_Gartengeräte.pdf" target="_blank" rel="noopener noreferrer">Mietbedingungen Gartengeräte</PdfLink><br/>

            <Title>AGB's</Title>

            <p><b>MIETPARK ALLGEMEINE GESCHÄFTSBEDINGUNGEN</b></p>

            <p><b>Angebote, Vertragsabschluss, Geltungsbereich</b></p>

            <p>&nbsp;</p>

            <p>1.1. Die vorliegenden Allgemeinen Mietvertragsbedingungen
                gelten für alle Vermietungsangebote und Mietverträge des Vermieters sowie der hieraus
                resultierenden Vermietungen von Baumaschinen, Baugeräte, Anhänger und
                Gartengeräte. <span >Diese Mietvertragsbedingungen gelten
                    ausschließlich, entgegenstehende oder von diesen Bedingungen abweichende
                    Bedingungen des Mieters erkennt der Vermieter nicht an, es sei denn, er hätte
                    ausdrücklich schriftlich ihrer Geltung zugestimmt. Die Bedingungen des
                    Vermieters gelten auch dann, wenn er in Kenntnis entgegenstehender oder von
                    seinen Mietvertragsbedingungen abweichender Bedingungen des Mieters die
                    Vermietung an den Mieter vorbehaltlos ausführt.</span></p>

            <p><span >1.2. Im Einzelfall getroffene, individuelle
                Vereinbarungen mit dem Mieter (einschließlich Nebenabreden, Ergänzungen und
                Änderungen) haben in jedem Fall Vorrang vor diesen Mietvertragsbedingungen.</span></p>

            <p>1.3. Falls nichts Abweichendes angegeben, sind die Angebote
                des Vermieters unverbindlich.</p>

            <p>1.4. Der Vermieter behält sich ausdrücklich die Vermietung
                eines anderen als des angebotenen Mietgegenstandes aus triftigem Grund des
                Vermieters vor, falls der andere Mietgegenstand für den durch den Mieter
                beabsichtigten Gebrauch in vergleichbarer Weise geeignet ist und die Vermietung
                des anderen Mietgegenstandes unter Berücksichtigung der Interessen des
                Vermieters für den Mieter zumutbar ist.&nbsp;</p>

            <p><span >1.5. Der Vermieter behält sich vor, bei
                Abschluss des Vertrages, oder während der Laufzeit des Vertrages, die
                Gestellung einer im Sinne des § 315 BGB angemessenen verzinslichen Kaution zu
                verlangen.</span></p>

            <p><span >&nbsp;</span></p>

            <p><b>Dauer des Mietverhältnisses</b></p>

            <p>2.1. Falls die Vertragsparteien keinen abweichenden
                Mietvertragsbeginn vereinbart haben, beginnt das Mietverhältnis mit
                Unterzeichnung des Mietvertrages durch beide Vertragsparteien oder im Zeitpunkt
                der Übergabe des Mietgegenstandes, je nachdem, welches Ereignis früher
                eintritt.</p>

            <p>2.2. Das Mietverhältnis eines über einen befristeten
                Zeitraum abgeschlossenen Mietvertrages endet mit Ablauf des vereinbarten
                letzten Tages; während dieser Zeit ist eine ordentliche Kündigung
                ausgeschlossen. <span >Der vorstehende zweite Nebensatz gilt
                    entsprechend, falls im Rahmen eines auf unbestimmte Zeit abgeschlossenen
                    Mietvertrages eine Mindestmietzeit vereinbart wurde.</span></p>

            <p><span >Das Mietverhältnis eines über eine
                unbestimmte Laufzeit abgeschlossenen Mietvertrages kann von beiden
                Vertragsparteien ordentlich gekündigt werden unter Einhaltung einer Frist von</span></p>

            <p><span >- einem Tag, wenn der Mietpreis pro Tag</span></p>

            <p><span >- zwei Tage, wenn der Mietpreis pro Woche</span></p>

            <p><span >zu zahlen ist.</span></p>

            <p><span >&nbsp;</span></p>

            <p>2.3. Wird der Mietgegenstand durch den Mieter mit
                Einverständnis des Vermieters unmittelbar einem Nachmieter überlassen, endet
                das Mietverhältnis mit dem Mieter, sobald dem Vermieter die vorbehaltlose
                Empfangsbestätigung des Nachmieters zugegangen ist mit Wirkung zu dem in der
                Empfangsbestätigung angegebenen Empfangszeitpunkt. Der Mieter ist verpflichtet,
                dem Vermieter ein schriftliches Übergabeprotokoll zu übergeben, welches sowohl
                vom Mieter, als auch vom Nachmieter unterzeichnet ist und in dem der Zustand
                des Mietgegenstandes insbesondere auch hinsichtlich eventueller Schäden
                dokumentiert ist.</p>

            <p><b>Übergabe des Mietgegenstandes</b></p>

            <p>3.1. Der Vermieter ist verpflichtet, den Mietgegenstand
                mängelfrei und betriebsbereit zu übergeben. <span >Der Mieter ist
                    verpflichtet, den Mietgegenstand bei Übergabe auf Freiheit von erkennbaren
                    Mängeln und Betriebsbereitschaft zu prüfen. Der Mieter ist verpflichtet, später
                    auftretende Mängel unverzüglich schriftlich zu rügen.</span></p>

            <p>3.2 Mit der Übergabe des Mietgegenstandes gehen sämtliche
                Gefahren aus einer Verletzung der Obhutspflicht bezüglich des Mietgegenstandes
                durch den Vermieter auf den Mieter über, insbesondere diejenigen des
                Unterganges, des Verlustes, des Diebstahls, der Verschlechterung, Beschädigung
                und der vorzeitigen Abnutzung. <span >Für den Fall des Diebstahls,
                    der Beschädigung durch Dritte und sonstiger Delikte ist der Mieter zur
                    unverzüglichen Anzeige bei der zuständigen Polizeidienststelle und der
                    diesbezüglichen Beweissicherung sowie zur unverzüglichen Benachrichtigung des
                    Vermieters in allen vorgenannten Fällen verpflichtet.</span></p>

            <p>Baumaschinen (Bagger, Rüttelplatten, Stampfer) sind durch
                den Vermieter gegen Brand, Vandalismus und Diebstahl versichert. Für alle
                anderen Geräte haftet der Mieter zu 100% persönlich oder hat dies mit seiner
                Haftpflichtversicherungzu klären. Der Mieter verpflichtet sich zur Zahlung der
                zeitanteiligen Versicherungsprämie. <span >Zum Ersatz der
                    Selbstbeteiligung im Rahmen des Versicherungsvertrages ist der Mieter nur
                    verpflichtet, soweit den Mieter für den Eintritt des Versicherungsfalles ein
                    Verschulden trifft oder er den Eintritt in sonstiger Weise zu vertreten hat.
                    Der Mieter ist verpflichtet, auf seine Kosten eine Haftpflichtversicherung
                    abzuschließen.</span></p>

            <p>3.3 Unbeschadet Ziff. 8 dieser Mietvertragsbedingungen ist
                eine etwaige Schadensersatzpflicht des Vermieters aufgrund Verzuges auf
                höchstens zwei Tagesnettomieten pro Verzugstag begrenzt.</p>

            <p><b>Nutzung des Mietgegenstandes durch den Mieter, Reparatur-
                und Wartungsarbeiten, Einsatzort, Gebrauchsüberlassung, Pfändungs- und sonstige
                Maßnahmen Dritter, Versicherungspflicht</b></p>

            <p><span >4.1. Der Mieter verpflichtet sich, den
                Mietgegenstand ausschließlich an dem vertraglich vereinbarten Einsatzort im
                Rahmen der betriebstechnischen Eignung des Mietgegenstandes einzusetzen und zu
                bedienen und durch den Vermieter oder durch vom Vermieter autorisierte
                Unternehmen warten zu lassen und ausschließlich technisch geeignete und
                gesetzlich zulässige Betriebsmittel zu verwenden.</span></p>

            <p><span >Der Mieter ist verpflichtet, den
                Mietgegenstand gemäß den Bedienungs- und Wartungsvorschriften auf eigene Kosten
                täglich zu pflegen, insbesondere durch Durchführung von Schmierdiensten.
                Schäden aus unterlassener Pflege gehen zu Lasten des Mieters. Im Übrigen sind
                durch den Mieter und seine Erfüllungsgehilfen die Bedienungs- und
                Wartungsanleitungen vollumfänglich zu beachten und insbesondere eine
                Überlastung des Mietgegenstandes zu vermeiden.</span></p>

            <p>4.2. Der Vermieter ist jederzeit berechtigt, den Mietgegenstand
                zu besichtigen und technisch zu untersuchen oder untersuchen zu lassen. <span
                >Die Kosten hierfür trägt der Vermieter, falls sich nicht ein Mangel
                    herausstellt, den der Mieter pflichtwidrig nicht beseitigt bzw. nicht dem
                    Vermieter angezeigt hat.</span></p>

            <p><span >4.3. Der Mieter ist verpflichtet, sämtliche
                durch ihn zu vertretenden Reparaturarbeiten auf seine Kosten durch den
                Vermieter ausführen zu lassen.</span></p>

            <p>4.4. Die Verbringung des Mietgegenstandes an einen anderen
                Einsatzort, insbesondere in das Ausland, bedarf der vorherigen schriftlichen
                Zustimmung des Vermieters und des Nachweises des durch den Mieter zu
                beschaffenden umfassenden Versicherungsschutzes, insbesondere für die Risiken
                des Diebstahls, Brandes und sonstigen Abhandenkommens und der zeitweiligen</p>

            <p><span >Nichtrückführbarkeit. Die Versicherung Muss
                auf den Vermieter als Begünstigten abgeschlossen werden. Vor einer
                entsprechenden Verbringung des Mietgegenstandes an einen anderen Einsatzort ist
                der Mieter verpflichtet, dem Vermieter eine entsprechende Versicherungsbestätigung
                gemäß den Bestimmungen dieser Ziff. 4.4. zu übergeben.</span></p>

            <p><span >4.5. Die Untervermietung oder sonstige
                Gebrauchsüberlassung an Dritte ist ausgeschlossen.</span></p>

            <p>4.6. Sollten Dritte durch Pfändung, Beschlagnahme oder
                aufgrund sonstiger Rechte oder unbefugt Rechte an dem Mietgegenstand geltend
                machen oder diesen befugt oder unbefugt in Besitz nehmen, ist der Mieter
                verpflichtet, den Vermieter unverzüglich nachweislich schriftlich und vorab
                mündlich bzw. telefonisch zu benachrichtigen sowie vorab den oder die Dritten
                auf das Eigentum des Vermieters ebenfalls unverzüglich und nachweislich
                schriftlich hinzuweisen und diesen schriftlichen Hinweis dem Vermieter
                ebenfalls unverzüglich zu übermitteln.</p>

            <p><span >Der Mieter ist verpflichtet, dem Vermieter
                sämtliche Kosten zur Wiedererlangung zu ersetzen und auf Verlangen des
                Vermieters für die Rechtsverfolgungskosten angemessene Vorschüsse zu leisten,
                sofern er die Kostenverursachung zu vertreten hat.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >4.7. Der Vermieter bietet bei
                Vertragsabschluss gegen einen angemessenen Kostenzuschlag den Abschluss einer
                Maschinenbruchversicherung an, die auch die weiteren typischen Risiken wie
                Diebstahl u.a. abgedeckt. Wenn der Mieter die Maschinenbruchversicherung
                abgeschlossen hat, ist im Schadenfall die in den Versicherungsbedingungen des
                Versicherers geregelte und im Mietvertrag ausgewiesene Selbstbeteiligung des
                Versicherungsnehmers/Mieters durch den Mieter zu tragen, sofern er den Schaden
                zu vertreten hat. Sofern der Mieter diese Versicherung nicht abschließt,
                verpflichtet sich der Mieter, den Mietgegenstand während der Mietzeit gegen
                alle einsatztypischen Gefahren zugunsten des Vermieters zu versichern,
                insbesondere gegen Brand, Diebstahl, sonstigen Verlust, fehlerhafte Bedienung,
                Baustellenunfälle jeglicher Art und bei für den Straßenverkehr zugelassenen
                Maschinen auch gegen die Risiken des Straßenverkehrs, soweit diese Risiken zu
                handelsüblichen Konditionen versicherbar sind und dem Vermieter auf Verlangen
                den Versicherungsschutz vor Übergabe des Mietgegenstandes nachzuweisen.</span></p>

            <p><span >Der Mieter tritt sämtliche gegenwärtigen
                und künftigen Ansprüche aus der von ihm abgeschlossenen Versicherung an den
                Vermieter ab. Dieser nimmt die Abtretung an. Soweit in den
                Versicherungsbedingungen eine Abtretbarkeit der Ansprüche ausgeschlossen sein
                sollte, ermächtigt der Mieter den Vermieter unwiderruflich zur Geltendmachung
                und zum Inkasso des Anspruchs gegen den Versicherer.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >4.8. Der Mieter trägt die Kosten der
                Betriebsmittel. Vorhandene Betriebsmittel werden bei Übergabe und Restbestände
                bei Rückgabe des Mietgegenstandes vermerkt und entsprechend abgerechnet.</span></p>

            <p><span >&nbsp;</span></p>

            <p><b><span >Rückgabe des Mietgegenstandes,
                Schadenersatz, Versicherungder Mietsache</span></b></p>

            <p><span >5.1. Der Mieter ist verpflichtet, den
                Mietgegenstand einschließlich sämtlichen etwaigen Zubehörs fristgemäß im Sinne
                der Ziffer 2 dieser Mietvertragsbedingungen mängelfrei und gesäubert
                zurückzugeben.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >5.2. Bei Rückgabe des Mietgegenstandes
                durch den Mieter erfolgt eine unverzügliche gemeinsame Überprüfung des
                Mietgegenstandes durch beide Vertragsparteien.</span></p>

            <p><span >Werden bei der Überprüfung Mängel
                festgestellt, wird der Zustand des Mietgegenstandes in einem durch den Mieter
                und den Vermieter zu unterzeichnenden Rückgabeprotokoll festgehalten. Soweit im
                Einzelfall über das Vorliegen von Mängeln keine Einigkeit der Vertragsparteien
                besteht, ist jede Vertragspartei berechtigt, die Aufnahme ihrer Ansicht in das
                Rückgabeprotokoll zu verlangen.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >Jede der Vertragsparteien kann die
                Untersuchung des Mietgegenstandes durch einen durch die für den Vermieter
                örtlich zuständige Industrie- und Handelskammer zu benennenden öffentlich
                bestellten Sachverständigen verlangen. Die Sachverständigenkosten tragen die
                Vertragsparteien je nach dem Ergebnis der Feststellungen des Sachverständigen
                über das Vorhandensein oder Nichtvorhandensein von Mängeln im Verhältnis ihres
                Obsiegens oder Unterliegens. Der Sachverständige hat auch auftragsgemäß zu
                dokumentieren, in welchem Verhältnis die Vertragsparteien&nbsp;die
                Sachverständigenkosten zu tragen verpflichtet sind.</span></p>

            <p><span >Soweit zahlenmäßig umfangreiche
                Mietgegenstände zurückgenommen werden, wie beispielsweise Kleinmaterial,
                erfolgt die Rücknahme durch den Vermieter unter dem Vorbehalt der
                nachträglichen Überprüfung.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >5.3 Werden Mängel, Schäden oder
                Wartungsbedürftigkeit festgestellt, ist der Vermieter verpflichtet, den Mieter
                unverzüglich zu benachrichtigen und ihm eine Nachprüfung durch Besichtigung zu
                ermöglichen. Der Mieter ist in diesem Fall nur dann zum Ersatz der Reparatur-
                und Wartungskosten verpflichtet, wenn der Vermieter dem Mieter nachweist, dass
                der Mieter die Mängel, Schäden oder Wartungsarbeiten zu vertreten hat, bzw.
                diese während der Vermietung an den Mieter entstanden sind.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >5.4 Ist der Mietgegenstand aufgrund durch
                den Mieter zu vertretender Umstände, insbesondere aufgrund von Schäden,
                vorzeitig notwendig gewordener Wartungsarbeiten oder mangels Rückgabe mit
                sämtlichem Zubehör oder aufgrund sonstiger durch den Mieter zu vertretender
                Umstände nicht anderweitig vermietbar, ist der Mieter schadenersatzpflichtig.
                Für den Umfang der Schadenersatzpflicht gilt nachfolgende Ziffer 5.5.
                entsprechend.</span></p>

            <p><span >Die Geltendmachung weiterer
                Schadenersatzforderungen des Vermieters bleibt vorbehalten. Der Vermieter wird
                sich jedoch um die Geringhaltung des Schadens pflichtgemäß bemühen.</span></p>

            <p><span >Erfolgt die Rückgabe des Mietgegenstandes unvollständig,
                insbesondere hinsichtlich etwaigen Zubehörs, ist der Vermieter berechtigt und
                verpflichtet, nach seinem Ermessen etwa verfügbares Mietzubehör oder andere
                fehlende Teile mietweise und gegen zusätzliche Vergütung zur Verfügung zu
                stellen, um eine anderweitige Vermietung zu ermöglichen. 5.5 Ist dem Mieter die
                Rückgabe des Mietgegenstandes aus einem durch ihn zu vertretenden Grund
                unmöglich geworden oder würden bei durch den Mieter zu vertretenden Mängeln
                oder Schäden die Reparaturkosten mehr als 60 % des Zeitwertes betragen, ist der
                Mieter zu einer sofortigen Kündigung des Mietvertrages berechtigt. Der Mieter
                ist jedoch verpflichtet, Schadenersatz in Höhe des Zeitwertes des mängelfreien
                und uneingeschränkt betriebsbereiten Mietgegenstandes zuzüglich einer
                Wiederbeschaffungskostenpauschale von brutto 2 % zu verlangen.
                Diese&nbsp;Verpflichtung besteht&nbsp;unbeschadet des Rechts des Vermieters,
                die Entstehung höherer Wiederbeschaffungskosten nachzuweisen und zu verlangen,
                sowie eine Nutzungsentschädigung in Höhe des tagesanteiligen Mietzinses für
                einen angemessenen Zeitraum zur Ersatzbeschaffung durch den Vermieter,
                längstens jedoch für einen Monat zu leisten, falls der Vermieter die sofortige
                Nachvermietbarkeit nachweist, anderenfalls in Höhe von 65 % der Monatsmiete für
                den jeweiligen tagesanteiligen Ausfall. Dem Mieter ist der Nachweis gestattet,
                dass ein Schaden bzw. ein Nutzungsausfall überhaupt nicht entstanden ist oder
                wesentlich niedriger ist. Der Vermieter ist verpflichtet, alle ihm zumutbaren
                Anstrengungen zur Geringhaltung des Schadens zu unternehmen.</span></p>

            <p><span >&nbsp;</span></p>

            <p><b><span >6. Berechnung des Mietzinses und
                Abgeltungsumfang</span></b></p>

            <p><span >6.1. Der Mietzins versteht sich
                einschließlich der gesetzlichen Mehrwertsteuer in ihrer jeweiligen Höhe und
                sonstiger Preisbestandteile ab der Betriebsstätte des Vermieters.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >6.2. Sofern nicht schriftlich eine
                abweichende Vereinbarung getroffen wurde, errechnet sich der Gesamtmietzins aus
                dem Tagesmietzins multipliziert mit der auf Tage bezogenen Mietdauer. Die Tage
                der Übergabe und Rücknahme werden als volle Miettage berechnet.</span></p>

            <p><span >6.3. Bei mit Betriebsstundenzählern
                ausgestatteten Mietgegenständen werden acht Einsatzstunden als ein Einsatz
                innerhalb eines Werktages im Durchschnitt zugrunde gelegt.</span></p>

            <p><span >Nutzt der Mieter den Mietgegenstand mehr
                als acht Stunden im Laufe eines Werktages, erhöht sich der Mietzins für jede
                weitere angefangene Stunde um 1/8 des Tagesmietpreises.</span></p>

            <p><span >Pro Werktag ist jedoch mindestens eine
                durchschnittliche Mindesteinsatzzeit von acht Stunden zugrunde zu legen und zu
                vergüten.</span></p>

            <p><span >&nbsp;</span></p>

            <p><b><span >7. Fälligkeit, Zahlung des Mietzinses,
                Verzug</span></b></p>

            <p><span >7.1. Die Abrechnung des Mietzinses und
                sonstiger Forderungen des Vermieters erfolgt nach Rückgabe des Mietgegenstandes
                einschließlich der gesetzlichen Mehrwertsteuer bzw. bei
                Schadensersatzansprüchen ohne Mehrwertsteuer.&nbsp;Der Mietzins ist bei
                Rückgabe durch Barzahlung/Rechnung fällig.</span></p>

            <p><span >&nbsp;</span></p>

            <p>7.2. Der Vermieter ist berechtigt, jederzeit eine
                angemessene Vorauszahlung und/oder Abschlagszahlung vom Mieter zu verlangen. .</p>

            <p><span >7.3. Die berechneten Beträge sind
                spätestens innerhalb einer Woche ab Rechnungszugang bei dem Mieter ohne Abzüge
                eingehend bei dem Vermieter zahlbar.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >&nbsp;</span></p>

            <p><b><span >8. Haftungsbegrenzung des Vermieters</span></b></p>

            <p><span >&nbsp;</span></p>

            <p><span >Schadensersatzansprüche können vom Mieter
                gegen den Vermieter ausschließlich geltend gemacht werden in folgenden Fällen:</span></p>

            <p><span>·</span><span
            > bei vorsätzlicher Pflichtverletzung durch den Vermieter,</span></p>

            <p><span>·</span><span
            > bei einer grob fahrlässigen Pflichtverletzung des Vermieters oder
                einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung eines gesetzlichen
                Vertreters oder Erfüllungsgehilfen des Vermieters,</span></p>

            <p><span>·</span><span
            > bei Schäden aus der Verletzung des Lebens, des Körpers oder der
                Gesundheit, die auf einer fahrlässigen Pflichtverletzung des Vermieters oder
                einer vorsätzlichen oder fahrlässigen Pflichtverletzung eines gesetzlichen
                Vertreters oder Erfüllungsgehilfen des Vermieters beruht,</span></p>

            <p><span>·</span><span
            > falls der Vermieter nach Produkthaftungsgesetz für Personenschäden
                oder Sachschäden an privat genutzten Gegenständen haftet,</span></p>

            <p><span>·</span><span
            > bei der schuldhaften Verletzung wesentlicher Vertragspflichten,
                soweit die Erreichung des Vertragszwecks gefährdet wird, allerdings begrenzt
                hinsichtlich des vertragstypischen und voraussehbaren Schadens.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >Im Übrigen ist die Schadensersatzhaftung
                des Vermieters gegenüber dem Mieter ausgeschlossen.</span></p>

            <p><span >&nbsp;</span></p>

            <p><b><span >9. Sicherungsrechte des Vermieters,
                Forderungsabtretungen</span></b></p>

            <p><span >9.1. Der Mieter tritt mit Unterzeichnung
                des Mietvertrages sicherungshalber in Höhe der gegenwärtigen und künftigen
                Forderungen des Vermieters sämtliche gegenwärtigen und künftigen Forderungen
                und Leistungsansprüche gegen seine Versicherer (soweit dies nach den
                Bedingungen seiner Versicherer zulässig ist), ab.</span></p>

            <p><span >&nbsp;</span></p>

            <p><span >Der Vermieter nimmt die Abtretungen an.</span></p>

            <p><span >Der Vermieter verpflichtet sich gegenüber
                dem Mieter, die Forderungsabtretung gegenüber dem oder den Drittschuldner(n) so
                lange nicht offenzulegen, wie der Mieter sich nicht in Verzug befindet oder das
                Mietverhältnis nicht aus wichtigem Grunde gekündigt ist.</span></p>

            <p><b><span >&nbsp;</span></b></p>

            <p><b><span >&nbsp;</span></b></p>

            <p><b><span >Aufrechnung und Abtretung</span></b></p>

            <p><span >10.1. Das Recht, mit Gegenansprüchen
                aufzurechnen, steht dem Mieter nur insoweit zu, als seine Gegenansprüche
                unbestritten, rechtskräftig festgestellt oder in einem rechtshängigen Verfahren
                entscheidungsreif sind.</span></p>

            <p><span >10.2. Die Befugnis des Mieters, Ansprüche
                aus diesem Vertrag an Dritte abzutreten oder Dritte zur Einziehung von
                Forderungen oder der Geltendmachung von Ansprüchen aus diesem Vertrag zu
                ermächtigen, wird ausgeschlossen.</span></p>

            <p><b><span >Leistungsverweigerungsrecht des
                Vermieters</span></b></p>

            <p><span >&nbsp;</span></p>

            <p><span >Der Vermieter kann die ihm obliegende
                Leistung gegenüber dem Mieter verweigern, wenn nach Abschluss des Mietvertrags
                erkennbar wird, dass sein Anspruch auf den Mietzins durch mangelnde
                Leistungsfähigkeit des Mieters gefährdet wird. Dieses
                Leistungsverweigerungsrecht entfällt, wenn der Mietzins jedoch bewirkt oder
                Sicherheit für ihn geleistet wird.</span></p>

            <p><b><span >&nbsp;</span></b></p>

            <p><b><span >Kündigung aus wichtigem Grunde durch die
                Vertragsparteien</span></b></p>

            <p><span >12.1. Unbeschadet der ordentlichen
                Kündigung gemäß Ziffer 2.2 dieser Mietvertragsbedingungen kann der Mietvertrag
                von beiden Vertragspartei bei Vorliegen eines wichtigen Grundes außerordentlich
                gekündigt werden. Besteht der wichtige Grund in der Verletzung einer Pflicht
                aus dem Vertrag, ist die Kündigung erst nach erfolglosem Ablauf einer zur
                Abhilfe bestimmten Frist oder nach erfolgloser Abmahnung zulässig.</span></p>

            <p><span >12.2. Ein wichtiger Kündigungsgrund für den
                Vermieter liegt insbesondere vor, wenn</span></p>

            <p><span >- der Mieter mit der Zahlung von nicht nur
                im Sinne des § 320 Abs. 2 BGB geringfügigen Verbindlichkeiten in Verzug ist,</span></p>

            <p><span >- Vollstreckungsmaßnahmen gegen den Mieter
                durchgeführt werden, der Mieter den Mietgegenstand trotz Abmahnung durch den
                Vermieter in technisch schädigender Weise oder sonstiger erheblich
                vertragswidriger Weise benutzt,</span></p>

            <p><span >- der Mieter den Mietgegenstand unbefugt
                Dritten überlässt oder an einen vertraglich nicht vereinbarten Ort verbringt.</span></p>

            <p><span >&nbsp;</span></p>

            <p><b><span >Rechtswahl</span></b></p>

            <p><span >13.1. Alle Fragen aus oder im Zusammenhang
                mit dem zwischen den Vertragspartnern zugrunde liegenden Mietverhältnis
                unterliegen dem Recht der Bundesrepublik Deutschland.</span></p>

        </Container>
    );
}