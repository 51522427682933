import React, { useState } from "react";
import { useId } from '@fluentui/react-hooks';
import { DatePicker, DayOfWeek, DefaultButton, Dialog, DialogFooter, DialogType, IDatePickerStrings, PrimaryButton, Stack, TextField } from "@fluentui/react";
import WebUtils from "utils/WebUtils";
import { IProductItem } from "pages/Product";
import { Store } from "react-notifications-component";
import { validateEmail } from "utils/Utils";
import ReCaptcha from "@matt-block/react-recaptcha-v2";

interface IRequestDialogProps {
    onClose: () => void,
    productItem: IProductItem
}

export default function RequestDialog(props: IRequestDialogProps) {
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const dialogStyles = { main: { maxWidth: 450 } };

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [name, setName] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [zip, setZip] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [remarks, setRemarks] = useState<string>('');
    const [captcha, setCaptcha] = useState<string>();

    const [sendWasPressed, setSendWasPressed] = useState<boolean>(false);


    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Reservierung anfragen',
        closeButtonAriaLabel: 'Schließen',
        subText: 'Bitte füllen sie das Formular aus und senden sie es ab, wir werden uns dann schnellstens bei ihnen melden.',
    };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles
        }),
        [labelId, subTextId],
    );

    const onTriggerClose = () => {
        props.onClose();
    }

    const onSend = async () => {
        if(!name || !startDate || !endDate || !street || !zip || !email || !phone ||!captcha) {
            setSendWasPressed(true);
            return;
        }

        const data = {
            name: name,
            email: email,
            phone: phone,
            street: street,
            zip: zip,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            remarks: remarks,
            product: props.productItem.name,
            captcha: captcha
        };

        const rawResponse = await fetch(WebUtils.buildBackendUrl('/form/send2'), {
            method: 'POST',
            body: JSON.stringify(data)
        });

        const content = await rawResponse.json();

        let statusTtitle = 'Anfrage gesendet!';
        let statusMessage = 'Ihre Anfrage wurde erfolgreich versendet. Wir werden uns so schnell wie möglich bei Ihnen melden.';

        if (!content.ok) {
            statusTtitle = 'Fehler!';
            statusMessage = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.';
        }

        props.onClose();

        Store.addNotification({
            title: statusTtitle,
            message: statusMessage,
            type: content.ok ? "success" : "danger",
            insert: "top",
            container: "center",
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    const datePickerStrings: IDatePickerStrings = {
        isRequiredErrorMessage: 'Bitte wählen sie ein Datum aus.',
        invalidInputErrorMessage: 'Ungültiges Datum.',
        isOutOfBoundsErrorMessage: 'Datum außerhalb des gültigen Bereichs.',
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        goToToday: 'Heute'
    };

    const formatDate = (date: Date | undefined): string => {
        return date?.toLocaleDateString("de-DE", { year: 'numeric', month: '2-digit', day: '2-digit' }) ?? '';
    }

    const errorMessage = 'Dieses Feld wird benötigt';

    return (
        <Dialog hidden={false} onDismiss={onTriggerClose} dialogContentProps={dialogContentProps} modalProps={modalProps}>
            <Stack tokens={{ childrenGap: 5 }}>
                <DatePicker
                    value={startDate}
                    onSelectDate={(v: any) => setStartDate(v)}
                    label="Von"
                    placeholder="Bitte wählen..."
                    firstDayOfWeek={DayOfWeek.Monday}
                    minDate={new Date()}
                    strings={datePickerStrings}
                    formatDate={date => formatDate(date)}
                    isRequired />
                <DatePicker
                    value={endDate}
                    onSelectDate={(v: any) => setEndDate(v)}
                    label="Bis"
                    placeholder="Bitte wählen..."
                    firstDayOfWeek={DayOfWeek.Monday}
                    minDate={new Date()}
                    strings={datePickerStrings}
                    formatDate={date => formatDate(date)}
                    isRequired />
                <TextField
                    label="Name"
                    errorMessage={sendWasPressed && !name ? errorMessage : undefined}
                    value={name}
                    onChange={(_: any, v: any) => setName(v)}
                    required />
                <TextField
                    errorMessage={sendWasPressed && !street ? errorMessage : undefined}
                    label="Straße / Hausnummer"
                    value={street} onChange={(_: any, v: any) => setStreet(v)}
                    required />
                <TextField
                    errorMessage={sendWasPressed && !zip ? errorMessage : undefined}
                    label="PLZ / Ort"
                    value={zip}
                    onChange={(_: any, v: any) => setZip(v)}
                    required />
                <TextField
                    errorMessage={sendWasPressed && (!email || !validateEmail(email)) ? 'Bitte geben Sie eine gültige E-Mail Adresse ein' : undefined}
                    label="Email"
                    value={email}
                    onChange={(_: any, v: any) => setEmail(v)}
                    required />
                <TextField
                    errorMessage={sendWasPressed && !phone ? errorMessage : undefined}
                    label="Telefon"
                    value={phone}
                    onChange={(_: any, v: any) => setPhone(v)}
                    required />
                <TextField
                    label="Bemerkungen"
                    value={remarks}
                    onChange={(_: any, v: any) => setRemarks(v)}
                    multiline
                    rows={4} />

                <ReCaptcha
                    siteKey="6Ldbb8slAAAAAN6fowWSNiMDf_2Kf0dkJnJU2PrR"
                    theme="light"
                    size="normal"                    
                    onSuccess={(captcha) => setCaptcha(captcha)}
                    onError={() => console.error("Something went wrong, check your conenction")}
                    onExpire={() => console.error("Verification has expired, re-verify.")}
                />
            </Stack>

            <DialogFooter>
                <PrimaryButton text="Absenden" onClick={onSend} />
                <DefaultButton text="Abbrechen" onClick={onTriggerClose} />
            </DialogFooter>
        </Dialog>
    );
}