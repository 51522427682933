
class WebUtils {
    public static buildUrl(relativeUrl: string): string {
        const globalObject = window;
        if (globalObject && "location" in globalObject && "host" in globalObject.location) {
            return 'https://' + globalObject.location.host + relativeUrl;
        }
        return relativeUrl;
    }

    public static buildBackendUrl(path: string): string {
        const globalObject = window;

        if (!(globalObject && "location" in globalObject) || !("host" in globalObject.location)) {
            return path;
        }

        // if app is in dev env, use the dev backend
        if (globalObject.location.host == 'localhost:3000') {
            return 'https://localhost:8443' + path;
        }
        return 'https://' + globalObject.location.host + path;
    }
}

export default WebUtils;