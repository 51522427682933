import { Link } from "react-router-dom";
import styled from "styled-components";
import { Stack, Text } from "@fluentui/react";


export const StyledStack = styled(Stack)({
    marginTop: '2vh'    
});

export const StyledNavLink = styled(Link)<{ checked?: boolean }>(
    ({ checked }) => {
        return {
            height: '4vh',
            lineHeight: '4vh',
            color: '#000000',

            background: checked ? 'rgba(255, 0, 0, 0.5)' : 'transparent',
            padding: '0vw 2vw 0vw 2vw',
            userSelect: 'none',

            fontSize: '1rem',

            fontWeight: '600',

            textTransform: 'uppercase',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

            textDecoration: 'none',

            ":hover": {
                cursor: 'pointer',
                textDecoration: 'underline',
            }
        }
    }
);

export const NormalText = styled(Text)({
    fontSize: '1rem',
    fontWeight: '600',
    userSelect: 'none',
});

export const MainStack = styled(Stack)({});

export const ContentStack = styled(Stack)({
    overflowY: 'auto',
    marginBottom: '4vh'
});