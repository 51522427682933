import React, { useEffect } from "react";
import styled from "styled-components";
import WebUtils from "utils/WebUtils";
import { IProductItem } from "./Product";
import { Link, useParams } from "react-router-dom";
import { NormalText, StyledStack } from "Styles";

export default function Category() {
    const [products, setProducts] = React.useState<IProductItem[]>([]);
    const [categoryName, setCategoryName] = React.useState<string>('');

    const params = useParams();

    const loadData = async () => {
        console.count('loadData');
        const response = await fetch(WebUtils.buildBackendUrl('/product/list?category=' + params.catId), {
            headers: {
                'Accept-Encoding': 'gzip',
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: "no-cors", // no-cors, *cors, same-origin
        });

        const data: IProductItem[] = await response.json();
        if (data.length === 0) {
            return;
        }

        setProducts(data);
        setCategoryName(data[0].categoryName);
    };

    // same as componentDidMount
    useEffect(() => {
        loadData();
    }, [params.catId]);

    if (products.length === 0) {
        return <></>;
    }

    return (
        <StyledStack>
            <NormalText>Folgende {categoryName} stehen zur Verfügung:</NormalText>
            <StyledUnorderedList>
                {products.map((item: IProductItem, index: number) => (
                    <StyledUnorderedListItem key={index}>
                        <ProductItemText to={`/category/${params.catId}/${item.id}`}>{item.name}</ProductItemText><br />
                        <RedText>Preis: {item.price_day} € / Tag Netto</RedText>
                    </StyledUnorderedListItem>
                ))}
            </StyledUnorderedList>
        </StyledStack>
    );
}

const StyledUnorderedList = styled.ul({
    width: '30vw'
});

const StyledUnorderedListItem = styled.li({
    paddingTop: '1vh'
});

const RedText = styled(NormalText)({
    color: '#ff0000',
});

const ProductItemText = styled(Link)({
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: '600',
    userSelect: 'none',
    color: '#000000',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline'
    }
});