import React, { useState } from "react";
import { Stack, Image, Link, TextField } from "@fluentui/react";
import { NormalText, StyledStack } from "Styles";
import { Store } from 'react-notifications-component';
import ReCaptcha from "@matt-block/react-recaptcha-v2";

import styled from "styled-components";
import WebUtils from "utils/WebUtils";
import { validateEmail } from "utils/Utils";

export default function Contact() {
    const [name, setName] = React.useState<string>();
    const [email, setEmail] = React.useState<string>();
    const [message, setMessage] = React.useState<string>();
    const [sendWasPressed, setSendWasPressed] = useState<boolean>(false);

    const [captcha, setCaptcha] = useState<string>();

    const onSave = async () => {

        if (!name || !email || !message || !captcha) {
            setSendWasPressed(true);
            return;
        }

        const data = {
            name: name,
            email: email,
            message: message,
            captcha: captcha
        };

        const rawResponse = await fetch(WebUtils.buildBackendUrl('/form/send'), {
            method: 'POST',
            body: JSON.stringify(data)
        });

        const content = await rawResponse.json();

        let statusTtitle = 'Nachricht gesendet!';
        let statusMessage = 'Ihre Nachricht wurde erfolgreich versendet. Wir werden uns so schnell wie möglich bei Ihnen melden.';

        if (!content.ok) {
            statusTtitle = 'Fehler!';
            statusMessage = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.';
        }

        Store.addNotification({
            title: statusTtitle,
            message: statusMessage,
            type: content.ok ? "success" : "danger",
            insert: "top",
            container: "center",
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    };

    const errorMessage = 'Dieses Feld wird benötigt';

    return (
        <StyledStack horizontalAlign={'center'} horizontal>
            <ContactFormStack>
                <StyledTextField
                    label={'Name'}
                    autoComplete={'off'}
                    required={true}
                    errorMessage={sendWasPressed && !name ? errorMessage : undefined}
                    onChange={(_: any, val?: string) => setName(val)} />
                <StyledTextField
                    label={'E-Mail Addresse'}
                    autoComplete={'off'}
                    errorMessage={sendWasPressed && (!email || !validateEmail(email)) ? 'Bitte geben Sie eine gültige E-Mail Adresse ein' : undefined}
                    onChange={(_: any, val?: string) => setEmail(val)}
                    required={true} />
                <StyledTextArea
                    label={'Nachricht'}
                    autoComplete={'off'}
                    multiline
                    resizable={false}
                    rows={10}
                    errorMessage={sendWasPressed && !message ? errorMessage : undefined}
                    onChange={(_: any, val?: string) => setMessage(val)} required={true} />
                <ReCaptcha
                    siteKey="6Ldbb8slAAAAAN6fowWSNiMDf_2Kf0dkJnJU2PrR"
                    theme="light"
                    size="normal"
                    onSuccess={(captcha) => setCaptcha(captcha)}
                    onError={() => console.error("Something went wrong, check your conenction")}
                    onExpire={() => console.error("Verification has expired, re-verify.")}
                />
                <SendButton onClick={onSave}>Absenden</SendButton>
            </ContactFormStack>

            <ContactInfoStack>
                <AddressDetails>
                    Adressdetails:<br />
                    <br />
                    RSD-Vermietung<br />
                    Zienkenerstraße 4C<br />
                    79379 Müllheim-Hügelheim<br />
                    <br />
                    Email: <Link href={'mailto:info@rsd-vermietung.de'}>info@rsd-vermietung.de</Link><br />
                    <br />
                    <br />
                    WhatsApp Nachricht:<br />
                    <br />
                </AddressDetails>
                <Image src={WebUtils.buildUrl('/assets/img/wa-qr.png')} width={'6vw'} height={'auto'} shouldStartVisible />
            </ContactInfoStack>
        </StyledStack>
    );
}

const ContactFormStack = styled(Stack)({
    marginTop: '2vh',
    width: '30vh',
});

const ContactInfoStack = styled(Stack)({
    marginTop: '2vh',
    marginLeft: '2vw'
});


const StyledTextField = styled(TextField)({
    border: 0,
    marginTop: '1vh',
    fontSize: '1rem',
    width: '100%'
});

const StyledTextArea = styled(TextField)({
    border: 0,
    padding: 0,
    marginTop: '1vh',
    marginBottom: '2vh',
    fontSize: '1rem',
    width: '100%',
    resize: 'none'
});

const SendButton = styled.button({
    fontSize: '1rem',
    fontWeight: '400',
    background: 'rgba(255, 174, 26, 0.27)',
    borderColor: 'rgba(255, 174, 26, 0.27)',
    borderRadius: '4px',
    marginTop: '20px',
    padding: '5px',
    width: '15vw'
});

const AddressDetails = styled(NormalText)({
    marginTop: '2vh'
});