import { Link } from "@fluentui/react";
import { NormalText, StyledStack } from "Styles";
import React from "react";

export default function Imprint() {
    return (
        <StyledStack horizontalAlign="center">
            <NormalText>
                <b>RSD-Vermietung</b><br />
                Zienkenerstraße 4C<br />
                79379 Müllheim-Hügelheim<br />
                Inhaber: Robin Danehl<br />
                <br />
                E-Mail: <Link href={'mailto:info@rsd-vermietung.de'}>info@rsd-vermietung.de</Link><br />
                <br />
                Steuer-Nummer: 12063/02125<br />
            </NormalText>
        </StyledStack>
    );
}
