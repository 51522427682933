import React from "react";
import { Stack, Text, Image } from "@fluentui/react";
import styled from "styled-components";

export default function Home() {
    return (
        <Stack horizontalAlign="center" verticalAlign="center">
            <Text1>Herzlich Willkommen bei RSD-Vermietung</Text1>
            <Text2>Hier können Sie Baumaschinen, Anhänger sowie Gartengeräte mieten.</Text2>
            <Stack horizontal>
                <StyledImage width={'18vw'} height={'auto'} src={'/assets/img/bagger.jpg'} shouldStartVisible/>
                <StyledImage width={'18vw'} height={'auto'} src={'/assets/img/ruttler1.jpg'} shouldStartVisible/>
                <StyledImage width={'18vw'} height={'auto'} src={'/assets/img/ruttler2.jpg'} shouldStartVisible/>
                <StyledImage width={'18vw'} height={'auto'} src={'/assets/img/dumper.jpg'} shouldStartVisible/>
            </Stack>
        </Stack>
    );
}

const Text1 = styled(Text)({
    fontSize: '1.2rem',
    fontWeight: '600',
    paddingTop: '2vh',
    paddingBottom: '4vh',
    textAlign: 'center'
});

const Text2 = styled(Text)({
    fontSize: '1rem',
    fontWeight: '600',
    paddingBottom: '4vh',
    textAlign: 'center'
});

const StyledImage = styled(Image)({
    paddingRight: '2vw'
});