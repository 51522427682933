import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { Stack, Image } from "@fluentui/react";

import Contact from "pages/Contact";
import Imprint from "pages/Imprint";
import Terms from "pages/Terms";
import Home from "pages/Home";

import Navbar from "Navbar";
import Category from "pages/Category";
import Product from "pages/Product";
import Delivery from "pages/Delivery";
import { ContentStack, MainStack } from "Styles";

import 'react-notifications-component/dist/theme.css';

export default function App(): React.ReactElement {
    return (
        <MainStack verticalFill grow>
            <Stack horizontalAlign="center" horizontal>
                <Image width={'10vw'} height={'auto'} src={'/assets/img/logo.png'} />
            </Stack>
            <Navbar />
            <ContentStack horizontalAlign="center" verticalFill={true}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/imprint" element={<Imprint />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/delivery" element={<Delivery />} />
                    <Route path="/category/:catId" element={<Category />} />
                    <Route path="/category/:catId/:prodId" element={<Product />} />
                </Routes>
            </ContentStack>
            <Navbar isFooter />
        </MainStack>
    );
};
