import React from "react";
import { Stack } from "@fluentui/react";
import styled from "styled-components";
import { resolvePath } from "react-router-dom";
import { useMatch } from "react-router-dom";
import { StyledNavLink } from "Styles";

export default function Navbar(props: { isFooter?: boolean }): React.ReactElement {
    return props.isFooter ? <Footer /> : <Header />;
}

function Header() {
    return (
        <HeaderStack horizontalAlign="center" verticalAlign="center" horizontal>
            <NavLink to="/">Start</NavLink>
            <NavLink to="/category/1">Baumaschinen</NavLink>
            <NavLink to="/category/2">Gartengeräte</NavLink>
            <NavLink to="/category/3">Anhänger</NavLink>
            <NavLink to="/delivery">Lieferung & Abholung</NavLink>
            <NavLink to="https://www.ebay-kleinanzeigen.de/s-bestandsliste.html?userId=118120678" target="_blank">eBay Kleinanzeigen</NavLink>
        </HeaderStack>
    )
}

function Footer() {
    return (
        <FooterStack horizontalAlign="center" verticalAlign="center" horizontal>
            <NavLink to="/contact">Kontakt</NavLink>
            <NavLink to="/imprint">Impressum</NavLink>
            <NavLink to="/terms">AGB's</NavLink>
        </FooterStack>
    )
}

function NavLink({ to, target, children, ...props }: { to: string, target?: string, children: React.ReactNode }) {
    const resolvedPath = resolvePath(to);
    
    const useEnd = resolvedPath.pathname === '/';
    const isActive = useMatch({ path: resolvedPath.pathname, end: useEnd });

    return (
        <StyledNavLink target={target} to={to} checked={isActive !== null} {...props}>{children}</StyledNavLink>
    )
}

const HeaderStack = styled(Stack)({
    minHeight: '4vh',
    backgroundColor: '#FFA500'
});

const FooterStack = styled(Stack)({
    minHeight: '4vh',
    width: '100%',
    backgroundColor: '#FFA500',
    position: 'absolute',
    bottom: 0
});
