import * as React from "react";
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";

import App from "App";

const root = ReactDOMClient.createRoot(document.getElementById('app') as HTMLElement);
root.render(
    <React.StrictMode>
        <ReactNotifications />
        <BrowserRouter>            
            <App />
        </BrowserRouter>
    </React.StrictMode>
);