import { Stack, Text, Image } from "@fluentui/react";
import RequestDialog from "RequestDialog";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import WebUtils from "utils/WebUtils";

export interface IProductItem {
    id: number;
    name: string;
    categoryName: string;
    description: string;
    price_day: number;
    price_week: number;
    rental_deposit: number;
    image: string;
}

export default function Product() {
    const [productItem, setProductItem] = React.useState<IProductItem>();
    const [requestDialogVisible, setRequestDialogVisible] = React.useState(false);

    const params = useParams();

    const loadData = async () => {
        console.count('loadData');
        const response = await fetch(WebUtils.buildBackendUrl('/product/item?id=' + params.prodId), {
            headers: {
                'Accept-Encoding': 'gzip',
                'Content-Type': 'application/json'
            },
            method: 'GET',
            mode: "no-cors", // no-cors, *cors, same-origin
        });

        const data: IProductItem = await response.json();

        setProductItem(data);
    };

    const toggleRequestDialog = () => {
        setRequestDialogVisible(!requestDialogVisible);
    }

    // same as componentDidMount
    useEffect(() => {
        loadData();
    }, [params.prodId]);

    if (productItem === undefined) {
        return <></>;
    }

    let description = productItem.description.replace(/{{day-rent}}/g, productItem.price_day.toString());
    description = description.replace(/{{week-rent}}/g, productItem.price_week.toString());
    description = description.replace(/{{deposit}}/g, productItem.rental_deposit.toString());

    return (
        <>
            <StyledStack horizontal>
                <StyledStack2>
                    <CaptionText>{productItem.name}</CaptionText>
                    <DecriptionText>{description}</DecriptionText>
                    <RequestButton onClick={toggleRequestDialog}>Reservierung anfragen</RequestButton>
                </StyledStack2>
                <Image src={'/assets/img/' + productItem.image} width={'30vw'} height={'auto'} shouldStartVisible/>
            </StyledStack>
            {requestDialogVisible && <RequestDialog onClose={toggleRequestDialog} productItem={productItem}/>}
        </>
    );
}

const StyledStack = styled(Stack)({
    paddingTop: '2vh',
    paddingBottom: '2vh',
    marginLeft: '10vw',
});

const StyledStack2 = styled(Stack)({
    width: '30vw',
});

const CaptionText = styled(Text)({
    fontSize: '1rem',
    fontWeight: '600',
    paddingBottom: '1vh'
});

const DecriptionText = styled.div({
    fontSize: '1rem',
    whiteSpace: 'pre-line',
    inlineSize: '30vw',
    overflowWrap: 'break-word'
});

const RequestButton = styled.button({
    fontSize: '1rem',
    fontWeight: '400',
    background: 'rgba(255, 174, 26, 0.27)',
    borderColor: 'rgba(255, 174, 26, 0.27)',
    borderRadius: '25px',
    marginTop: '20px',
    padding: '5px',
    width: '15vw'
});